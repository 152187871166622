/************************************ FONTS ************************************/
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,700);
@import url(https://weloveiconfonts.com/api/?family=entypo|fontawesome|zocial);
/* entypo */
[class*="entypo-"]:before {
  font-family: "entypo", sans-serif;
}
/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: "FontAwesome", sans-serif;
}
/* zocial */
[class*="zocial-"]:before {
  font-family: "zocial", sans-serif;
}
@font-face {
  font-family: "icomoon";
  src: url("https://jlalovi-cv.herokuapp.com/font/icomoon.eot");
  src: url("https://jlalovi-cv.herokuapp.com/font/icomoon.eot?#iefix")
      format("embedded-opentype"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.ttf") format("truetype"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.woff") format("woff"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.svg#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-cloudy:before {
  content: "\e60f";
}
.icon-sun:before {
  content: "\e610";
}
.icon-cloudy2:before {
  content: "\e611";
}
/************************************* END FONTS *************************************/
