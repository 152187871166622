@import url("./normalize.css");
@import url("./fonts.css");
@import url("./main.css");
@import url("./containers.css");
@import url("./Loader.css");

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: #1f253d;
}

.badge_container {
  list-style-type: none;
  margin: 7px;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.header1 {
  font-size: 23px;
}

.header2 {
  font-size: 17px;
}

.text {
  font-size: 15px;
}

.link {
  text-decoration: none;
  font-size: 15px;
}
.icon:hover {
  color: #fff;
}

.text_white {
  color: #fff;
}

.text_gray {
  color: #9099b7;
}

.quote {
  color: #c9d0e4;
  text-align: left;
  font-size: 0.8em;
  line-height: 1.3em;
  margin-top: 15px;
}

.quote_author {
  text-align: right;
  font-size: 0.7em;
  margin-top: 10px;
  margin-right: 7px;
}

.input-container {
  position: relative;
}
input[type="text"] {
  width: 260px;
  height: 50px;
  margin-left: 20px;
  margin-bottom: 20px;
  padding-left: 45px;
  background: #50597b;
  color: #fff;
  border: solid 1px #1f253d;
  border-radius: 5px;
}
input[type="text"]::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}
input[type="text"]:-moz-input-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
input[type="text"]::-moz-input-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}
input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}
input[type="text"]:focus {
  outline: none; /* removes the default orange border when focus */
  border: 1px solid #11a8ab;
}
.input-icon {
  font-size: 22px;
  position: absolute;
  left: 31px;
  top: 10px;
}
.input-icon.password-icon {
  left: 35px;
}

.horizontal-list li {
  float: left;
}

.clear {
  clear: both;
}

.icon {
  font-size: 25px;
}

.download_icon {
  height: 25px;
  width: auto;
}

.titular {
  display: block;
  line-height: 60px;
  margin: 0;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.button {
  display: block;
  width: 175px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.button:hover {
  text-decoration: none;
}

.arrow-btn-container {
  position: relative;
}
.arrow-btn {
  position: absolute;
  display: block;
  width: 60px;
  height: 60px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.arrow-btn:hover {
  text-decoration: none;
}
.arrow-btn.left {
  border-top-left-radius: 5px;
}
.arrow-btn.right {
  border-top-right-radius: 5px;
  right: 0;
  top: 0;
}
.arrow-btn .icon {
  display: block;
  font-size: 18px;
  border: 2px solid #fff;
  border-radius: 100%;
  line-height: 17px;
  width: 21px;
  margin: 20px auto;
  text-align: center;
}
.arrow-btn.left .icon {
  padding-right: 2px;
}

.profile-picture {
  border-radius: 100%;
  overflow: hidden;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.big-profile-picture {
  margin: 0 auto;
  border: 5px solid #50597b;
  width: 150px;
  height: 150px;
}
.small-profile-picture {
  border: 2px solid #50597b;
  width: 40px;
  height: 40px;
}

@media screen and (min-width: 1280px) {
  .main-container {
    width: 100%;
    max-width: 1600px;
    max-height: 900px;
  }
}

@media screen and (min-width: 641px) and (max-width: 1279px) {
  .main-container {
    max-width: 1200px;
    max-height: 700px;
  }
}

@media screen and (min-width: 481px) and (max-width: 640px) {
  .main-container {
    max-width: 640px;
    max-height: none;
  }
}

@media screen and (max-width: 639px) {
  .main-container {
    max-width: 310px;
    max-height: none;
  }
}

@media screen and (orientation: portrait) {
  .main-container {
    max-width: 100%;
    max-height: none;
  }
}
