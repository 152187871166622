/** CONTAINERS **/
.container {
  float: left;
  width: 300px;
}
.block {
  width: 300px;
  background: #394264;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 20px rgb(0, 0, 0);
}

.block_achievments {
  height: 237px;
  padding-top: 7px;
}

.block_cert {
  margin: 7px auto 0px 7px;
  font-size: 0.8rem;
}

.block_cert:last-child {
  margin-bottom: 7px;
}

.horizontal-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social {
  padding: 15px;
}

.profile {
  height: 360px;
  padding-top: 30px;
}

.icon {
  float: right;
  line-height: 18px;
  width: 23px;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}

.icon:hover {
  color: #e64c65;
  border-color: #e64c65;
}

.sign {
  font-weight: 1000;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    font-size: 18px;
    text-align: center;
    color: #dee5ff;
    animation: neon 0.08s ease-in-out infinite alternate;
  }
}

/*-- Animation Keyframes --*/

@keyframes neon {
  from {
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.92),
      0 0 50px rgba(202, 228, 225, 0.14), 0 0 32px rgba(30, 132, 242, 0.52),
      0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78),
      0 0 54px rgba(30, 132, 242, 0.92);
  }
  to {
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
      0 0 50px rgba(202, 228, 225, 0.12), 0 0 42px rgba(30, 132, 242, 0.58),
      0 0 42px rgba(30, 132, 242, 0.94), 0 0 78px rgba(30, 132, 242, 0.88),
      0 0 100px rgba(30, 132, 242, 1);
  }
}

.user-name {
  margin: 25px 0 16px;
  text-align: center;
}
.profile-description {
  width: 210px;
  margin: 0 auto;
  text-align: center;
}
.profile-options {
  padding-top: 23px;
}
.profile-options li {
  border-left: 1px solid #1f253d;
}
.profile-options p {
  margin: 0;
}
.profile-options a {
  display: block;
  width: 99px;
  line-height: 60px;
  text-align: center;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.profile-options a:hover {
  text-decoration: none;
  background: #50597b;
}
.profile-options a:hover.comments .icon {
  color: #fcb150;
}
.profile-options a:hover.views .icon {
  color: #11a8ab;
}
.profile-options a:hover.likes .icon {
  color: #e64c65;
}
.profile-options .icon {
  padding-right: 10px;
}
.profile-options .comments {
  border-top: 4px solid #fcb150;
}
.profile-options .views {
  border-top: 4px solid #11a8ab;
}
.profile-options .likes {
  border-top: 4px solid #e64c65;
}
