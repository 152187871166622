.main-container {
  font-family: "Ubuntu", sans-serif;
  color: #e9e9e9;

  margin: 20px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
  align-content: center;
}

.titular {
  font-size: 20px;
  height: 60px;
  background: #11a8ab;
}

.inner_text {
  margin: 15px auto 0 15px;
}

.inner_text:first-child {
  margin-top: 0;
}

.inner_text:last-child {
  margin-bottom: 7px;
}

.weather {
  visibility: visible;
  display: flex;
  height: 120px;
  opacity: 1;
}

.weather_hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.8s linear, opacity 0.8s linear;
}

.weather_description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 13px auto 13px 8px;
}

.badge {
  /* padding: 5px; */
  height: 25px;
  /* margin: 7px; */
  border-radius: 3px;
  /* background-color: #e64c65; */
}

.icon_habr {
  height: 25px;
  margin: auto;
}

.icon_small {
  height: 12px;
  margin-right: 7px;
}

.titular_weather {
  background: #cc324b;
  display: flex;
  flex-direction: row;
}

.titular_location {
  position: relative;
}

.current-day-date {
  font-size: 16px;
  padding-top: 16px;
}
.current-day-temperature {
  font-size: 70px;
}
.current-day-temperature .icon-cloudy {
  padding-left: 20px;
}

header {
  height: 80px;
}
.header-menu {
  font-size: 17px;
  line-height: 80px;
}
.header-menu li {
  position: relative;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}
.header-menu-tab {
  padding: 0 27px;
  display: block;
  line-height: 74px;
  font-size: 17px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.header-menu-tab:hover {
  background: #50597b;
  border-bottom: 4px solid #11a8ab;
  text-decoration: none;
}
.header-menu-tab .icon {
  padding-right: 15px;
}
.header-menu-number {
  position: absolute;
  line-height: 22px;
  padding: 0 6px;
  font-weight: 700;
  background: #e64c65;
  border-radius: 100%;
  top: 15px;
  right: 2px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header-menu li:hover .header-menu-number {
  text-decoration: none;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.profile-menu {
  float: right;
  height: 80px;
  padding-right: 20px;
}
.profile-menu p {
  font-size: 17px;
  display: inline-block;
  line-height: 76px;
  margin: 0;
  padding-right: 10px;
}
.profile-menu a {
  padding-left: 5px;
}
.profile-menu a:hover {
  text-decoration: none;
}
.small-profile-picture {
  display: inline-block;
  vertical-align: middle;
}

.image-inside {
  width: calc(100% - 14px);
  margin: 7px;
}
